import CountryHelper from '~pwi-modules/country-helper.js';
import DomHelper from '~pwi-modules/dom-helper.js';
import OrgHelper from '~pwi-modules/org-helper.js';
import Swipe from '~pwi-modules/swipe.js';
import Swup from 'swup';
import swal from 'sweetalert';

const swup = new Swup({
    cache: false,
    preload: false,
});
const countryHelper = new CountryHelper();
const orgHelper = new OrgHelper();
const swipe = new Swipe({
    env: $('input[name=env]').val(),
    onSuccess: initializePlaidSuccessCallback,
    publicKey: $('input[name=plaidPublicKey]').val(),
});

let $linkedAccountModal;
let $searchResults;

swup.on('pageView', swupPageLoad);

function accountsNeedReauth() {
    return !!+$('input#accountsNeedReauth').val();
}

function addDescriptiveTextToSuggestNonprofitForm() {
    let $container = $('.suggest-nonprofit-container');
    let find = $container.find('.suggest-nonprofit-description').length;
    if (!find) {
        let html = document.createElement('div');
        html.className = 'suggest-nonprofit-description';
        html.innerText = 'The nonprofit you are searching for isn\'t on Project World Impact yet. Please fill out ' +
            'the information below so we can get them connected to more people like you!';
        $('h3#suggest-nonprofit-title').after(html);
    }
}

function setUpSuggestNonprofitForm() {
    /** Submit "Suggest A Nonprofit" form **/
    $('form[name=suggestNonProfitForm]').unbind().on('submit', function( e ){
        e.preventDefault( );
        e.stopPropagation( );

        $.ajax({
            method: "post",
            url: "/suggest-nonprofit",
            beforeSend: function( ){
                $(".sub-suggest-nonprofit").find("input[type=submit]").val( "submitting" ).attr('disabled', true);
                $("form[name=suggestNonProfitForm]").find(".error").hide( );
            },
            data: $("form[name=suggestNonProfitForm]").serialize( ),
            dataType: 'json'
        }).done(function(data) {
            $(".sub-suggest-nonprofit").find("input[type=submit]").val( "submit" ).attr('disabled', false);

            if( ! data.status ){
                for( var x in data.errors ){
                    $(".error-" + x).html( data.errors[x] ).show( );
                }
            } else {
                $("form[name=suggestNonProfitForm]").find("input").val( );
                $(".suggest-thank-you").show( );

                try {
                    if (grecaptcha) {
                        grecaptcha.reset();
                    }
                } catch (exception) {
                    console.error(exception);
                }

                window.setTimeout(function( ){
                    $("#suggestNonProfitModal").hide( );
                    $(".suggest-thank-you").hide( );

                    location.reload();
                }, 3000);

                // Clear the modal inputs
                $("form[name=suggestNonProfitForm] input:not([type=submit]), form[name=suggestNonProfitForm] select").val('');
            }
        });
    });

    /** Click checkbox in "Suggest A Nonprofit" form **/
    $('.suggest-nonprofit-container input[name=i-am-poc]').unbind().on('change', function(event) {
        if ($(event.target).is(':checked')) {
            $('.suggest-nonprofit-container .hide-on-check').each(function(index, element) {
                $(element).hide();
                $(element).find('input').removeAttr('required');
            });
        } else {
            $('.suggest-nonprofit-container .hide-on-check').each(function(index, element) {
                $(element).show();
                $(element).find('input').attr('required', 'required');
            });
        }
    });
}

function buildSearchResultListHtml(response, type) {
    let $html = $('<div/>');
    response.forEach(element => {
        $html.append(
            '<div class="result-item beneficiary clickable" ' +
            'data-beneficiary-id="' + element._source[type + '_id'] + '" ' +
            'data-beneficiary-type="' + type + '">' + element._source[type + '_name'] + '</div>'
        );
    });
    return $html;
}

function emptySearchResultsList() {
    $searchResults.html('');
    $searchResults.hide();
}

function displaySearchResults(response, helper) {
    if (response.length) {
        $('.search-results').show();

        if (helper.getType() === 'org') {
            $('.suggest-nonprofit-container').hide();
        }

        $searchResults.html(buildSearchResultListHtml(response, helper.getType()));
    } else {
        emptySearchResultsList();

        if (helper.getType() === 'org') {
            $('.search-results').hide();
            $('.suggest-nonprofit-container').show();
        }
    }
}

function fixDeauthorizedAccounts() {
    if (accountsNeedReauth()) {
        showFixReauthAccountsDialog();
    }
}

function getShowChangeFundingAccountDialog() {
    let val = $('#showChangeFundingAccountDialog').val();
    if (!val) {
        return false;
    }
    return parseInt(val);
}

function handleSearchInput(event, helper) {
    $searchResults = $('#' + helper.getType() + '-search-results');

    let keyIsAlphaNumeric = DomHelper.keyIsAlphaNumeric(event.keyCode);
    let keyIsBackspace    = DomHelper.keyIsBackspace(event.keyCode);
    let deviceIsAndroid   = event.keyCode === 229;

    // Don't search except for certain key presses
    if (keyIsAlphaNumeric || keyIsBackspace || deviceIsAndroid) {

        // Don't search if the search field is empty
        let searchTerm = $(event.target).val();
        if (searchTerm) {
            // Do the search
            helper.searchByKeyword(searchTerm, (response) => {
                displaySearchResults(response, helper);
            });
        } else {
            emptySearchResultsList();
        }
    }
}

function handleCauseSearchInput(event) {
    let $button = $(event.target);
    let beneficiaryId = $button.attr('data-beneficiary-id');
    let beneficiaryName = $button.html();
    let beneficiaryType = $button.attr('data-beneficiary-type');

    swipe.linkBeneficiary({
        csrfToken: DomHelper.getCSRFToken(),
        userToken: DomHelper.getUserToken(),
        beneficiaryId: beneficiaryId,
        beneficiaryType: beneficiaryType,
        done: (response) => {
            console.log(response);
            location.href = '/swipe/mobile-app';
        }
    });
}

function hideBeneficiary(userOrgId) {
    $('.supported-org[data-user-org-id=' + userOrgId + ']').addClass('item-removed');
}

function initializeAttachCardForm() {
    // Create a Stripe client.
    var stripe = Stripe(DomHelper.getStripePublishableKey());

    // Create an instance of Elements.
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };

    // Create an instance of the card Element.
    var card = elements.create('card', {style: style});

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
            displayError.textContent = event.error.message;
        } else {
            displayError.textContent = '';
        }
    });

    // Handle form submission.
    var form = document.getElementById('stripe-payment-form');
    form.addEventListener('submit', function(event) {
        event.preventDefault();

        stripe.createToken(card).then(function(result) {
            if (result.error) {
                // Inform the user if there was an error.
                var errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
            } else {
                // Send the token to your server.
                stripeTokenHandler(result.token);
            }
        });
    });
}

function initializePlaidSuccessCallback(public_token, metadata) {
    console.log("onSuccess");
    console.log(public_token);
    console.log(metadata);

    // Navigate to the "name your card" page
    location.href = '/swipe/mobile-app/name-your-card?publicToken=' + public_token +
        '&accountId=' + metadata.account_id +
        '&accountMask=' + JSON.stringify(metadata.account.mask);
}

function replaceSubmitButtonInFundingAccountForm() {
    let $form = $('form#stripe-payment-form');
    let newSubmitButton =
        '<div class="col-xs-offset-1 col-xs-10">' +
        '<button type="submit" class="btn btn-primary large full-width" id="funding-account-edit-card">' +
        'ATTACH CARD' +
        '</button>' +
        '</div>';
    $form.append(newSubmitButton);
}

function setLinkedAccountModalValues(event) {
    let $linkedAccountModal = $('.modal#linked-card-modal');
    let $button             = $(event.relatedTarget);
    let unlinked            = $button.hasClass('unlinked');
    let cardName            = $button.attr('data-card-name');
    let cardNumber          = $button.attr('data-card-number');
    let userAccountId       = $button.attr('data-user-account-id');
    let needsReauth         = $button.attr('data-needs-reauth') === '1';
    let accountPublicToken  = $button.attr('data-card-public-token');
    let $cardName           = $linkedAccountModal.find('#card-name');
    let $cardNumber         = $linkedAccountModal.find('#card-number');
    let $unlinkCardButton   = $linkedAccountModal.find('button#unlink-linked-card-button');
    let $reauthCardButton   = $linkedAccountModal.find('button#reauth-card-button');
    let $deleteCardButton   = $linkedAccountModal.find('button#delete-linked-card-button');

    // Set the card name/number
    $cardName.html(cardName);
    $cardNumber.html(unlinked ? 'UNLINKED' : cardNumber);
    if (unlinked) {
        $cardNumber.addClass('unlinked');
    } else {
        $cardNumber.removeClass('unlinked');
    }

    // Set up the buttons
    $deleteCardButton.attr('data-callback-arg', userAccountId);
    $unlinkCardButton.attr('data-callback-arg', userAccountId);
    if (unlinked) {
        $unlinkCardButton.html('link');
        $unlinkCardButton.attr('data-callback', 'linkCard');
    } else {
        $unlinkCardButton.html('unlink');
        $unlinkCardButton.attr('data-callback', 'unlinkAccount');
    }

    // Show the reauth button if necessary
    if (needsReauth) {
        $reauthCardButton.removeClass('hidden');
        $unlinkCardButton.addClass('hidden');

        $reauthCardButton.attr('data-card-public-token', accountPublicToken);
        $reauthCardButton.attr('data-user-account-id', userAccountId);
    } else {
        $reauthCardButton.addClass('hidden');
        $unlinkCardButton.removeClass('hidden');
    }
}

function setMaxMinDonationAmounts(event) {
    event.preventDefault();

    let $form         = $(event.target);
    let $submitButton = $form.find('button[type=submit]');
    let max           = $form.find('input[name=max-donation]').val();
    let min           = $form.find('input[name=min-donation]').val();

    if (min >= 100) {
        swal({
            icon: 'warning',
            title: '$' + parseFloat(min).toFixed(2),
            text: 'You are setting a very high minimum donation. Are you sure you wish to continue?',
            buttons: {
                yes: {
                    text: 'YES',
                    value: true,
                    className: 'btn btn-warning large',
                    closeModal: true,
                },
                no: {
                    text: 'NO',
                    value: false,
                    className: 'btn btn-default large',
                    closeModal: true,
                }
            }
        }).then((value) => {
            if (value) {
                swipe.setMaxMinDonation({
                    csrfToken: DomHelper.getCSRFToken(),
                    userToken: DomHelper.getUserToken(),
                    max:       max,
                    min:       min,
                    done: (response) => {
                        console.log(response);
                        if (response.success) {
                            showSuccessMessage({
                                callback: function () {},
                                message:  'Your Min / Max donations have been successfully updated.',
                                title:    'AMOUNTS SAVED',
                            });
                        } else {
                            showErrorMessage(response.errors);
                        }
                    },
                    fail: (errors) => {
                        console.log('errors: ', errors);
                        showErrorMessage(errors);
                    }
                });
            }
        });
    } else {
        DomHelper.setLoading($submitButton);

        swipe.setMaxMinDonation({
            csrfToken: DomHelper.getCSRFToken(),
            userToken: DomHelper.getUserToken(),
            max:       max,
            min:       min,
            always: () => {
                DomHelper.unsetLoading($submitButton);
            },
            done: (response) => {
                console.log(response);
                if (response.success) {
                    showSuccessMessage({
                        callback: function () {},
                        message:  'Your Min / Max donations have been successfully updated.',
                        title:    'AMOUNTS SAVED',
                    });
                } else {
                    showErrorMessage(response.errors);
                }
            },
            fail: (errors) => {
                console.log('errors: ', errors);
                showErrorMessage(errors);
            }
        });
    }
}

function showCardDeletedDialog() {
    let content = document.createElement('div');
    let image = document.createElement('img');
    image.className = 'swipe-alert-image';
    image.src = 'https://res.cloudinary.com/project-world-impact/image/upload/c_scale,w_150/v1559575616/swipe/swipe-delete-card.png';
    let text = document.createElement('div');
    text.innerText = 'This account has been deleted';
    text.className = 'swipe-alert-title';
    content.appendChild(image);
    content.appendChild(text);

    swal({
        button: {
            text: 'OK',
            value: true,
            className: 'btn btn-primary large',
            closeModal: true,
        },
        className: 'full-width-buttons',
        content: content,
    }).then((value) => {
        if (value) {
            location.reload();
        }
    });
}

function showChangeFundingAccountDialog() {
    swal({
        title: 'Change funding account',
        text: 'PWI Swipe now allows you to link a credit card as your funding account. You currently ' +
            'have an ACH compatible link. Would you like to switch to a credit card?',
        buttons: {
            change: {
                text: 'CHANGE',
                value: true,
                className: 'btn btn-primary large',
                closeModal: true,
            },
            keep: {
                text: 'KEEP AS IS',
                value: false,
                className: 'btn btn-pwi-blue large',
                closeModal: true,
            },
        },
        // content: content,
    }).then((value) => {
        if (value) {
            location.href = '/swipe/mobile-app/attach-card';
        }
    });
}

function showErrorMessage(errors) {
    // Set error message text
    let message = 'There was a problem submitting your request:\n\n';
    if (errors) {
        // If the message argument is an array, then parse them into a <ul>.
        if (Array.isArray(errors)) {
            for (let index in errors) {
                message += '' + errors[index] + '\n';
            }
        }
        // The message argument is a string so just use it as-is.
        else if (typeof errors === 'string') {
            message += errors;
        }
        // Only strings and arrays will be accounted for. Use default error message otherwise.
        else {
            message = "There was a problem submitting your request.";
        }
    }
    // No error message arguments provided. Use the default error message.
    else {
        message = "There was a problem submitting your request.";
    }

    // Show the error message
    swal({
        icon: "error",
        title: "Oops!",
        text: message,
        button: {
            text: "OK",
            value: true,
            visible: true,
            className: "btn btn-danger large",
            closeModal: true,
        }
    });
}

function showFixReauthAccountsDialog() {
    let content = document.createElement('div');
    let image = document.createElement('img');
    image.style = 'margin-bottom: 20px;';
    image.src = 'https://res.cloudinary.com/project-world-impact/image/upload/c_scale,w_50/v1559075226/swipe/swipe-warning-icon.png';
    let text = document.createElement('div');
    text.innerText = 'Looks like you have an account that needs to be refreshed';
    content.appendChild(image);
    content.appendChild(text);

    swal({
        button: {
            text: 'RESOLVE NOW',
            value: 'resolve-now',
            className: 'btn btn-primary large full-width',
            closeModal: true,
        },
        className: 'full-width-buttons',
        content: content,
    }).then((value) => {
        if (value === 'resolve-now') {
            location.href = '/swipe/mobile-app/linked-accounts';
        }
    });
}

function showSuccessMessage(params) {
    params = {
        callback: params.callback || function () {},
        icon:     params.icon || null,
        message:  params.message,
        title:    params.title || 'Success!',
    };
    swal({
        button: {
            text: "OK",
            value: true,
            visible: true,
            className: "btn btn-primary large",
            closeModal: true,
        },
        className: 'full-width-buttons',
        icon: params.icon,
        text: params.message,
        title: params.title,
    }).then(() => {
        params.callback();
    });
}

function stripeTokenHandler(token) {
    console.debug('token: ', token);

    let $button = $('button#funding-account-edit-card');

    DomHelper.setLoading($button);

    swipe.setFundingAccount({
        csrfToken:   DomHelper.getCSRFToken(),
        always: () => {
            DomHelper.unsetLoading($button);
        },
        done: (response) => {
            if (response.success) {
                location.href = '/swipe/mobile-app';
            } else {
                showErrorMessage(response.errors);
            }
        },
        fail: (error) => {
            console.log('error: ', error);
        },
        stripeToken: JSON.stringify(token),
        userToken:   DomHelper.getUserToken()
    });
}

function submitNameYourCardForm(event) {
    event.preventDefault();

    let $form = $(event.target);
    let $submitButton = $form.find('button[type=submit]');
    let formValues = new FormData(event.target);
    formValues.append('userToken', DomHelper.getUserToken());

    DomHelper.setLoading($submitButton);

    $.ajax({
        url: '/swipe/api/linkaccount',
        data: formValues,
        processData: false,
        contentType: false,
        type: 'POST',
        dataType: 'text'
    }).done(function(response) {
        response = JSON.parse(response);
        console.log(response);

        if (response.success) {
            location.href = '/swipe/mobile-app/linked-accounts';
        } else {
            showErrorMessage(response.errors);
        }

    }).fail(function(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);

        showErrorMessage(textStatus);
    }).always(function () {
        DomHelper.unsetLoading($submitButton);
    });
}

function swupPageLoad() {
    $linkedAccountModal = $('.modal#linked-card-modal');
    $searchResults = $('.search-results');

    if ($('#card-element').length && typeof initializeAttachCardForm !== 'undefined') {
        initializeAttachCardForm();
    }

    replaceSubmitButtonInFundingAccountForm();

    // If any accounts need to be reauthorized, prompt the user to do so
    fixDeauthorizedAccounts();

    // Show change funding account modal
    if (getShowChangeFundingAccountDialog()) {
        showChangeFundingAccountDialog();
    }

    // Add descriptive text to the "suggest a nonprofit form"
    addDescriptiveTextToSuggestNonprofitForm();

    // Make sure that the checkbox in the "support a nonprofit" form works
    setUpSuggestNonprofitForm();
}

(function () {
    // Perform page load procedures
    swupPageLoad();

    // Click "add card" button
    $(document).on('click', '#link-account', () => {
        swipe.openPlaidDialog();
    });

    // Submit "name your card" form
    $(document).on('submit', '#name-your-card-form', submitNameYourCardForm);

    // Open "linked account" modal
    $(document).on('show.bs.modal', $linkedAccountModal, (event) => {
        setLinkedAccountModalValues(event);
    });

    // Type in the country search input
    $(document).on('keyup paste', '#cause-search', (event) => {
        let keyIsAlphaNumeric = DomHelper.keyIsAlphaNumeric(event.keyCode);
        let keyIsBackspace    = DomHelper.keyIsBackspace(event.keyCode);
        let deviceIsAndroid   = event.keyCode === 229;

        // Don't search except for certain key presses
        if (keyIsAlphaNumeric || keyIsBackspace || deviceIsAndroid) {

            let $searchInput = $(event.target);
            let searchTerm = $searchInput.val().toLowerCase();

            if (searchTerm) {
                $('.result-item').addClass('hidden');
                $('.result-item[data-cause-name*=' + searchTerm + ']').removeClass('hidden');
            } else {
                $('.result-item').removeClass('hidden');
            }
        }
    });

    // Type in the country search input
    $(document).on('keyup paste', '#country-search', (event) => {
        let keyIsAlphaNumeric = DomHelper.keyIsAlphaNumeric(event.keyCode);
        let keyIsBackspace    = DomHelper.keyIsBackspace(event.keyCode);
        let deviceIsAndroid   = event.keyCode === 229;

        // Don't search except for certain key presses
        if (keyIsAlphaNumeric || keyIsBackspace || deviceIsAndroid) {

            let $searchInput = $(event.target);
            let searchTerm = $searchInput.val().toLowerCase();

            if (searchTerm) {
                $('.result-item').addClass('hidden');
                $('.result-item[data-country-name*=' + searchTerm + ']').removeClass('hidden');
            } else {
                $('.result-item').removeClass('hidden');
            }
        }
    });

    // Type in the org search input
    $(document).on('keyup paste', '#org-search', (event) => {
        handleSearchInput(event, orgHelper);
    });

    // Select a beneficiary
    $(document).on('click', '.result-item.beneficiary', handleCauseSearchInput);

    // Set max/min donation amounts
    $(document).on('submit', 'form#set-max-min-form', setMaxMinDonationAmounts);

    // Expand/collapse beneficiaries on the history page
    $(document).on('click', '.donation.clickable', (event) => {
        $(event.currentTarget).toggleClass('expanded');
    });

    // Click the 'Fix Now' button for a linked account
    $(document).on('click', 'button#reauth-card-button', (event) => {
        let $button     = $(event.currentTarget);
        let accountId	= $button.attr('data-user-account-id');
        let reauthToken	= $button.attr('data-card-public-token');

        // Initialize Link with the token parameter
        // set to the generated public_token for the Item
        swipe.reauthorizeAccount({
            accountId:   accountId,
            csrfToken:   DomHelper.getCSRFToken(),
            reauthToken: reauthToken,
            done: (public_token, metadata) => {
                console.log('public_token: ', public_token);
                console.log('metadata: ', metadata);

                $('.modal#linked-card-modal').modal('hide');

                location.reload();
            },
            fail: (errorMessage, metadata) => {
                console.log('errorMessage: ', errorMessage);
                console.log('metadata: ', metadata);

                showErrorMessage(errorMessage);
            }
        });
    });

    // Format the min/max donation to 2 decimals
    $(document).on('blur', 'input#min-donation, input#max-donation', (event) => {
        let $input = $(event.target);
        let value = $input.val();

        $input.val(parseFloat(value).toFixed(2));
    });

})();

window.linkCard = (userAccountId, callback) => {
    swipe.linkCard({
        csrfToken: DomHelper.getCSRFToken(),
        userToken: DomHelper.getUserToken(),
        always: callback,
        done: function (response) {
            console.log(response);
            // Success. Reload the page.
            if (response.success) {
                location.reload();
            }

        },
        userAccountId: userAccountId
    });
};

window.removeAccount = (userAccountId, callback) => {
    swipe.removeAccount({
        always: callback,
        csrfToken: DomHelper.getCSRFToken(),
        userToken: DomHelper.getUserToken(),
        done: (response) => {
            console.log(response);
            // Success. Reload the page.
            if (response.success) {
                $('#linked-card-modal').modal('hide');
                showCardDeletedDialog();
            } else {
                showErrorMessage(response.errors);
            }
        },
        fail: (response) => {
            showErrorMessage(response);
        },
        userAccountId: userAccountId
    });
};

window.unlinkBeneficiary = (userOrgId, callback) => {
    swipe.unlinkBeneficiary({
        csrfToken: DomHelper.getCSRFToken(),
        userToken: DomHelper.getUserToken(),
        always: callback,
        done: (response) => {
            console.log(response);
            // Success. Reload the page.
            if (response.success) {
                hideBeneficiary(userOrgId);
                setTimeout(() => location.reload(), 1000);
            }
        },
        userOrgId: userOrgId
    });
};

window.unlinkAccount = (userAccountId, callback) => {
    swipe.unlinkAccount({
        csrfToken: DomHelper.getCSRFToken(),
        userToken: DomHelper.getUserToken(),
        always: callback,
        done: function (response) {
            console.log(response);
            // Success. Reload the page.
            if (response.success) {
                location.reload();
            }

        },
        userAccountId: userAccountId
    });
};
