export default class DomHelper {

    static getCSRFToken() {
        return $('meta[name=csrf-token]').attr('content');
    }

    static getUserToken() {
        return $('input[name=userToken]').val();
    }

    static getStripePublishableKey() {
        return $('input[name=stripePublishableKey]').val();
    }

    static keyIsAlpha(keyCode) {
        return keyCode >= 65 && keyCode <= 90;
    }

    static keyIsAlphaNumeric(keyCode) {
        return this.keyIsAlpha(keyCode) || this.keyIsNumeric(keyCode);
    }

    static keyIsBackspace(keyCode) {
        return keyCode === 8;
    }

    static keyIsNumeric(keyCode) {
        return keyCode >= 48 && keyCode <= 57;
    }

    static keyIsSpace(keyCode) {
        return keyCode === 32;
    }

    static setLoading($selector) {
        let loadingState = $selector.attr('data-loading-state');

        if (loadingState !== 'loading') {
            let originalHtml = $selector.html();
            let originalMinHeight = $selector.css('min-height') || '';
            let originalMinWidth = $selector.css('min-width') || '';
            let loadingHTML = '<i class="fa fa-cog fa-spin"></i>';
            let buttonWidth = $selector.outerWidth();
            let buttonHeight = $selector.outerHeight();

            // Save old values
            $selector.attr('data-original-html', originalHtml);
            $selector.attr('data-original-min-height', originalMinHeight);
            $selector.attr('data-original-min-width', originalMinWidth);

            // Set loading state
            $selector.attr('data-loading-state', 'loading');

            // Update the button style
            $selector.html(loadingHTML);
            $selector.addClass('disabled');
            $selector.attr('disabled', 'disabled');
            $selector.css({
                'min-height': buttonHeight,
                'min-width': buttonWidth,
            });
        }
    }

    static unsetLoading($selector) {
        let loadingState = $selector.attr('data-loading-state');

        if (loadingState === 'loading') {
            let originalHtml      = $selector.attr('data-original-html');
            let originalMinHeight = $selector.attr('data-original-html');
            let originalMinWidth  = $selector.attr('data-original-html');

            // Update the button style
            $selector.html(originalHtml);
            $selector.removeClass('disabled');
            $selector.removeAttr('disabled');
            $selector.css({
                'min-height': originalMinHeight,
                'min-width': originalMinWidth,
            });

            // Remove loading state and data attributes
            $selector.removeAttr('data-loading-state');
            $selector.removeAttr('data-original-html');
            $selector.removeAttr('data-original-min-height');
            $selector.removeAttr('data-original-min-width');
        }
    }
}
