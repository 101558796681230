import DomHelper from '~pwi-modules/dom-helper.js';

export default class Swipe {

    /**
     * Swipe constructor.
     *
     * Initializes the Swipe object with environment variables and API keys.
     *
     * @access public
     *
     * @param {Object}   params
     * @param {string}   params.env         The Plaid environment to use ('sandbox' or 'production').
     * @param {string}   params.publicKey   The Plaid API public key value.
     *
     * @param {function} [params.onSuccess] The default success callback for linking Plaid accounts.
     */
    constructor(params) {
        console.log('calling constructor: ', params);

        this.CONFIRM_ACCOUNT_REAUTH_URL = '/swipe/api/confirmReauth';
        this.DEFAULT_SUCCESS_CALLBACK   = params.onSuccess || function (){};
        this.PLAID_ERROR_LOG_URL        = '/swipe/api/log-plaid-error';
        this.EVENT_CALLBACK             = (eventName, metadata) => {
            if (eventName === 'ERROR') {
                this.logPlaidErrorToDatabase(metadata);
            }
        };
        this.LINK_ACCOUNT_URL           = '/swipe/api/setIncludedAccount';
        this.LINK_BENEFICIARY_URL       = '/swipe/api/linkBeneficiary';
        this.PLAID_ENV                  = params.env;
        this.PUBLIC_KEY                 = params.publicKey;
        this.REMOVE_ACCOUNT_URL         = '/swipe/api/removeAccount';
        this.RENAME_ACCOUNT_URL         = '/swipe/api/updateAccountName';
        this.SET_FUNDING_ACCOUNT_URL    = '/swipe/api/set-funding-account';
        this.SET_MAX_MIN_DONATION_URL   = '/swipe/api/set-max-min';
        this.UNLINK_BENEFICIARY_URL     = '/swipe/api/unlinkBeneficiary';

        this.plaid = null;

        this.initializePlaid();
    }

    /**
     * Confirms account reauthorization.
     *
     * Submits a request to reconcile with the PWI database that the user's Plaid
     * account has been successfully reauthenticated via the Plaid API.
     *
     * @param {Object}   params
     * @param {string}   params.csrfToken  Laravel CSRF token.
     * @param {number}   params.userToken  DB `pwi_users`.`user_key` value for the user calling the request.
     * @param {number}   params.accountId  DB `pwi_swipe_user_account`.`user_account_id` value for the account to reconcile.
     *
     * @param {function} [params.done]     Callback for successful reconciliation.
     * @param {function} [params.fail]     Callback for failed reconciliation.
     * @param {function} [params.always]   Callback that is always called regardless of reconciliation result.
     */
    confirmAccountReauth(params) {
        console.log('calling confirmAccountReauth');

        this.setDefaultCallbacks(params);

        fetch(this.CONFIRM_ACCOUNT_REAUTH_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:    params.csrfToken,
                userToken: params.userToken,
                accountId: params.accountId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param params
     */
    initializePlaid(params) {
        console.log('calling initializePlaid');
        // Handle missing params
        let onExit    = (params && params.onExit)    ? params.onExit     : function(){};
        let onLoad    = (params && params.onLoad)    ? params.onLoad     : function(){};
        let onSuccess = (params && params.onSuccess) ? params.onSuccess  : this.DEFAULT_SUCCESS_CALLBACK;
        let product   = (params && params.product)   ? params.product    : 'transactions';

        // Set Plaid arguments
        let data = {
            apiVersion:    'v2',
            clientName:    'PWI Swipe',
            env:           this.PLAID_ENV,
            forceIframe:   true,
            key:           this.PUBLIC_KEY,
            onEvent:       this.EVENT_CALLBACK,
            onLoad:        onLoad,
            onSuccess:     onSuccess,
            onExit:        onExit,
            product:       product,
            selectAccount: true
        };

        // If we are re-authorizing an existing account, pass in the account's public token
        if (params && params.reauthToken) {
            data.token = params.reauthToken;
        }

        // Initialize Plaid Link
        this.plaid = Plaid.create(data);
    }

    /**
     * TODO
     * @param params
     */
    linkCard(params) {
        console.log('calling linkCard');
        this.setDefaultCallbacks(params);

        fetch(this.LINK_ACCOUNT_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token: 		  params.csrfToken,
                userToken: 		  params.userToken,
                paymentAccountId: params.userAccountId,
                checked:		  1
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param params
     */
    linkBeneficiary(params) {
        console.log('calling linkBeneficiary');
        this.setDefaultCallbacks(params);

        fetch(this.LINK_BENEFICIARY_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:          params.csrfToken,
                // TODO: Convert to use user token
                userToken:       params.userToken,
                beneficiaryId:   params.beneficiaryId,
                beneficiaryType: params.beneficiaryType
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param metadata
     */
    logPlaidErrorToDatabase(metadata) {
        fetch(this.PLAID_ERROR_LOG_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:   DomHelper.getCSRFToken(),
                metadata: metadata,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log('logPlaidErrorToDatabase response: ', response);
            });
    }

    /**
     * TODO
     */
    openPlaidDialog() {
        console.log('calling openPlaidDialog');
        this.plaid.open();
    }

    /**
     * Reauthorizes the Plaid bank account.
     *
     * When a Plaid bank account's credentials change or otherwise requires reauthorization,
     * call this method to initiate the Plaid dialog to confirm the account credentials.
     *
     * @access public
     *
     * @param {Object}   params
     * @param {string}   params.csrfToken    Laravel CSRF token value.
     * @param {string}   params.reauthToken  Plaid token which specifies the account to reauthorize.
     * @param {number}   params.accountId    DB `pwi_swipe_user_account`.`user_account_id` value for the account to reauthorize.
     * @param {number}   params.userToken    DB `pwi_users`.`user_key` value for the user calling the request.
     *
     * @param {function} [params.done]       Callback for successful account reauthorization.
     * @param {function} [params.fail]       Callback for failed account reauthorization.
     * @param {function} [params.always]     Callback that is always called regardless of reauthorization result.
     */
    reauthorizeAccount(params) {
        console.log('calling reauthorizeAccount: ', params);

        this.setDefaultCallbacks(params);

        let always = () => {
            console.log('calling overridden always');
            // Reconcile reauthorized Plaid account with our database
            this.confirmAccountReauth({
                csrfToken: params.csrfToken,
                userToken: params.userToken,
                accountId: params.accountId,
                fail:      params.fail,
                done:      (response) => {
                    if (response.success) {
                        params.done();
                    } else {
                        let errors;
                        if (Array.isArray(response.message)) {
                            errors = response.message[0];
                        } else {
                            errors = response.message;
                        }
                        params.fail(errors, {});
                    }
                }
            });

            // Call the provided callback
            params.always();
        };

        this.initializePlaid({
            onEvent: (eventName, metadata) => {
                console.log('onEvent eventName: ', eventName);
                console.log('onEvent metadata: ', metadata);
            },
            onExit: (error, metadata) => {
                console.log('onExit reached');
                console.log('error: ', error);
                console.log('metadata: ', metadata);

                // Reinitialize Plaid with default settings
                this.initializePlaid();

                // Handle any errors
                if (error != null) {
                    params.fail(error, metadata);
                }

                // Always called
                always(error);
            },
            onSuccess: (public_token, metadata) => {
                console.log('onSuccess reached');
                console.log('public_token: ', public_token);
                console.log('metadata: ', metadata);

                // Reinitialize Plaid with default settings
                this.initializePlaid();

                // Success callback
                params.done(public_token, metadata);

                // Always called
                always(null);
            },
            product: ['transactions'],
            reauthToken: params.reauthToken,
        });

        this.openPlaidDialog();
    }

    /**
     * Remove Account
     *
     * Deletes a user's linked bank account or credit/debit card.
     *
     * @param params
     * @param {string}   params.csrfToken  Laravel CSRF token.
     * @param {number}   params.userToken  DB `pwi_users`.`user_key` value for the user calling the request.
     * @param {number}   params.accountId  DB `pwi_swipe_user_account`.`user_account_id` value for the account to reconcile.
     *
     * @param {function} [params.done]     Callback for successful reconciliation.
     * @param {function} [params.fail]     Callback for failed reconciliation.
     * @param {function} [params.always]   Callback that is always called regardless of reconciliation result.
     */
    removeAccount(params) {
        console.log('calling removeAccount');
        this.setDefaultCallbacks(params);

        fetch(this.REMOVE_ACCOUNT_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token: 		params.csrfToken,
                userToken: 		params.userToken,
                userAccountId:	params.userAccountId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * Renames the Swipe bank account.
     *
     * Submits a new account nickname for a linked Plaid bank account.
     *
     * @access public
     *
     * @param {Object}   params
     * @param {string}   params.csrfToken      Laravel CSRF token value.
     * @param {string}   params.cardName       New name for the card.
     * @param {number}   params.userAccountId  DB `pwi_swipe_user_account`.`user_account_id` value for the account to update.
     * @param {number}   params.userToken      DB `pwi_users`.`user_key` value for the user calling the request.
     *
     * @param {function} [params.done]         Callback for successful account reauthorization.
     * @param {function} [params.fail]         Callback for failed account reauthorization.
     * @param {function} [params.always]       Callback that is always called regardless of reauthorization result.
     */
    renameAccount(params) {
        console.log('calling renameAccount');
        this.setDefaultCallbacks(params);

        fetch(this.RENAME_ACCOUNT_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:        params.csrfToken,
                cardName:	   params.cardName,
                userAccountId: params.userAccountId,
                userToken:     params.userToken
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * Sets default callbacks.
     *
     * Many Swipe functions use `done()`, `fail()`, and `always()` callbacks. This method
     * sets these values to empty functions if they do not exist in the `params` argument.
     *
     * @access private
     *
     * @param params
     */
    setDefaultCallbacks(params) {
        console.log('calling setDefaultCallbacks');
        params.done   = params.done   || function(){};
        params.fail   = params.fail   || function(){};
        params.always = params.always || function(){};
    }

    /**
     * Set Funding Account.
     *
     * Saves a tokenized credit card.
     *
     * @access public
     *
     * @param {Object}   params
     * @param {string}   params.csrfToken      Laravel CSRF token value.
     * @param {string}   params.stripeToken    Credit card token returned from Stripe.
     * @param {number}   params.userToken      DB `pwi_users`.`user_key` value for the user calling the request.
     *
     * @param {function} [params.done]         Callback for successful account reauthorization.
     * @param {function} [params.fail]         Callback for failed account reauthorization.
     * @param {function} [params.always]       Callback that is always called regardless of reauthorization result.
     */
    setFundingAccount(params) {
        console.log('calling setFundingAccount');
        this.setDefaultCallbacks(params);

        fetch(this.SET_FUNDING_ACCOUNT_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:      params.csrfToken,
                stripeToken: params.stripeToken,
                userToken:   params.userToken,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param params
     */
    setMaxMinDonation(params) {
        console.log('calling setMaxMinDonation');
        this.setDefaultCallbacks(params);

        fetch(this.SET_MAX_MIN_DONATION_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:    params.csrfToken,
                userToken: params.userToken,
                max:       params.max,
                min:       params.min,
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param params
     */
    unlinkBeneficiary(params) {
        console.log('calling unlinkBeneficiary');
        this.setDefaultCallbacks(params);

        fetch(this.UNLINK_BENEFICIARY_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token:    params.csrfToken,
                userToken: params.userToken,
                userOrgId: params.userOrgId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }

    /**
     * TODO
     * @param params
     */
    unlinkAccount(params) {
        console.log('calling unlinkAccount');
        this.setDefaultCallbacks(params);

        fetch(this.LINK_ACCOUNT_URL, {
            method: 'POST',
            body: JSON.stringify({
                _token: 		  params.csrfToken,
                userToken: 		  params.userToken,
                paymentAccountId: params.userAccountId,
                checked:		  0
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(params.done)
            .catch(params.fail)
            .then(params.always, params.always);
    }
}
