import DomHelper from './dom-helper.js';

export default class OrgHelper {

    constructor() {
        this.abortController = new AbortController();
        this.countrySearchByKeywordUrl = '/country/api/search-by-keyword/';
        this.helperType = 'country';
        this.searchRequest = null;
    }

    getType() {
        return this.helperType;
    }

    getCountrySearchByKeywordUrl() {
        return this.countrySearchByKeywordUrl;
    }

    searchByKeyword(term, callback) {
        if (this.searchRequest) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }
        this.searchRequest = fetch(this.countrySearchByKeywordUrl + term, {
            method: 'POST',
            signal: this.abortController.signal,
            body: JSON.stringify({
                _token: DomHelper.getCSRFToken()
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(callback)
            .then(() => {
                this.searchRequest = null;
            })
            .catch(() => {});
    }
}
